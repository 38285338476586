import { Login } from 'components/session/login'
import { AppLayout } from 'layout/app.layout'
import { AuthLayout } from 'layout/auth.layout'
import { Form } from 'pages/form'
import { FormSuccess } from 'pages/form-sucess'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'

export function App() {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Outlet />}>
      <Route path="session" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route index element={<Navigate to="/session/login" />} />
      </Route>

      <Route element={<AppLayout />}>
        <Route path="form" element={<Form />} />
        <Route path="form-success" element={<FormSuccess />} />
      </Route>

      <Route index element={<Navigate to="/session/login" />} />
      <Route path="*" element={<Navigate to="/session/login" />} />
    </Route>,
  ),
)
