/* eslint-disable @typescript-eslint/naming-convention */
import type { MantineThemeOverride } from '@mantine/core'

export const theme: MantineThemeOverride = {
  primaryColor: 'custom-primary-color',
  fontFamily: 'Lato',
  colors: {
    'custom-primary-color': [
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
      '#850014',
    ],

    'custom-secondary-color': [
      '#FFFAF0',
      '#FFEED6',
      '#FFDFAD',
      '#FFC785',
      '#FFA759',
      '#CC8833',
      '#A85425',
      '#87411D',
      '#663313',
      '#461F0C',
    ],
  },
}
