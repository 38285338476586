import { AppShell, Box } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Outlet } from 'react-router-dom'

export function AuthLayout() {
  return (
    <AppShell display="flex" bg="#F1F1F1" h="100vh" mih={846} sx={{ justifyContent: 'center' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          maxWidth: '1200px',
          width: '100%',
          overflowX: 'hidden',
          justifyContent: 'center',
        })}
      >
        <Outlet />
      </Box>
    </AppShell>
  )
}
