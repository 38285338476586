import './i18n'
import '@mantine/core/styles.css'
import '@mantine/charts/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import { createRoot } from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { theme } from 'theme/theme'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'
import { App } from 'app'

const container = document.getElementById('root')
const root = createRoot(container!)

const Index = () => {
  return (
    <MantineProvider theme={theme} defaultColorScheme="light" stylesTransform={emotionTransform}>
      <MantineEmotionProvider>
        <App />
        <Notifications />
      </MantineEmotionProvider>
    </MantineProvider>
  )
}

root.render(<Index />)
