import { TextInput, Group, Button, Text, Box, Anchor, Image } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconPassword, IconMail } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { Logo } from 'components/logo'

interface FormValues {
  email: string
  password: string
}

export const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  function onSubmit(values: FormValues) {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      navigate('/form', { replace: true })
    }, 1500)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={30} pl={30}>
          <Logo width={150} />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={30}
          mb={50}
          h={240}
          sx={(theme) => ({
            background: theme.colors['custom-primary-color'][5],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: theme.white,
          })}
        >
          <Text ta="center" mt={20} fw={400} size="30px">
            {t('authentication.login.please-login')}
          </Text>
        </Box>
        <LoginForm form={form} isLoading={isLoading} onSubmit={onSubmit} />
      </Box>
    )
  }

  return (
    <Box pos="relative" right={-40} display="flex" sx={{ alignItems: 'center' }}>
      <Box
        h={550}
        w={500}
        bg="white"
        display="flex"
        sx={{
          flex: 1,
          flexFlow: 'column',
          padding: '20px 30px',
          zIndex: 100,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Logo width={100} />
        <Box mt={20} mb={40} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Text ta="center" fw={400} size="xl">
            {t('authentication.login.please-login')}
          </Text>
        </Box>

        <LoginForm form={form} isLoading={isLoading} onSubmit={onSubmit} />
      </Box>
      <Box
        w={500}
        bg="custom-primary-color.5"
        display="flex"
        h={700}
        left={-80}
        sx={{
          overflow: 'hidden',
          flexFlow: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          color: 'white',
          paddingTop: '237px',
          position: 'relative',
        }}
      >
        <Image pos="absolute" top={0} w={1200} h={700} src="https://cdn.expovisio.com/full-banner.png" alt="login" />
        {/* <Text
          top={50}
          pos="absolute"
          sx={{
            zIndex: 1,
          }}
          ta="center"
          size="xl"
          fw={400}
        >
          {t('authentication.login.welcome')}
        </Text> */}
      </Box>
    </Box>
  )
}

interface LoginFormProps {
  form: UseFormReturnType<
    {
      email: string
      password: string
    },
    (values: { email: string; password: string }) => {
      email: string
      password: string
    }
  >
  onSubmit: (values: FormValues) => void
  isLoading: boolean
}

function LoginForm({ form, isLoading, onSubmit }: LoginFormProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box w="80%" maw={650}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Box mb={10}>
            <TextInput
              size="md"
              sx={{
                input: {
                  borderRadius: '4px !important',
                },
              }}
              leftSection={<IconMail width={15} height={15} stroke="#cecece" />}
              label={t('authentication.login.email') || ''}
              {...form.getInputProps('email')}
            />
          </Box>

          <Box mb={30}>
            <TextInput
              mb={5}
              size="md"
              sx={{
                input: {
                  borderRadius: '4px !important',
                },
              }}
              leftSection={<IconPassword width={15} height={15} stroke="#cecece" />}
              type="password"
              label={t('authentication.login.password') || ''}
              {...form.getInputProps('password')}
            />
            <Box mt={10} display="flex" sx={{ justifyContent: 'flex-end' }}>
              <Anchor size="sm" underline="always" onClick={() => navigate('/session/login')}>
                {t('authentication.forgot-password.title')}
              </Anchor>
            </Box>
          </Box>

          <Group align="center">
            <Button
              w="100%"
              size="sm"
              radius="sm"
              type="submit"
              loading={isLoading}
              sx={{
                lineHeight: '22px',
                fontWeight: 400,
              }}
            >
              {t('common.login')}
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}
