import {
  Button,
  Checkbox,
  Container,
  FileInput,
  Grid,
  Group,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core'
import { useState } from 'react'

export function Form() {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Container>
      <Title mb={50} ta="center">
        Complaints Form
      </Title>
      <Grid mb={30}>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <TextInput mb={10} label="Company" placeholder="John's Company" />
          <TextInput mb={10} label="Name" placeholder="John Doe" />
          <TextInput mb={10} label="Email" placeholder="a@a.com" />
          <TextInput mb={10} label="Phone" placeholder="123-456-7890" />
          <Select
            label="Area"
            placeholder="Area where is the project"
            data={[
              { label: 'Area 1', value: 'area-1' },
              { label: 'Area 2', value: 'area-2' },
              { label: 'Area 3', value: 'area-3' },
              { label: 'Area 4', value: 'area-4' },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Select
            mb={10}
            label="Reason"
            placeholder="Select Reason"
            data={[
              { label: 'Reason 1', value: 'reason-1' },
              { label: 'Reason 2', value: 'reason-2' },
              { label: 'Reason 3', value: 'reason-3' },
              { label: 'Reason 4', value: 'reason-4' },
            ]}
          />
          <Textarea mb={10} label="Note" placeholder="Note" rows={4} />
          <FileInput
            mb={30}
            label="Evidences"
            description="Input evidences for your complaints"
            placeholder="Files"
            multiple
          />
          <Checkbox label="I agree to the terms and conditions" />
        </Grid.Col>
      </Grid>

      <Group mt={30} justify="right">
        <Button
          color="custom-primary-color"
          variant="light"
          loading={isLoading}
          onClick={() => {
            setIsLoading(true)
            setTimeout(() => {
              setIsLoading(false)
              alert('Form submitted')
            }, 1500)
          }}
        >
          Submit
        </Button>
      </Group>
    </Container>
  )
}
