import { AppShell, Group } from '@mantine/core'
import { Logo } from 'components/logo'
import { Outlet } from 'react-router'

export function AppLayout() {
  return (
    <AppShell
      padding="xl"
      header={{
        height: 60,
      }}
    >
      <AppShell.Header>
        <Group style={{ height: '100%' }} px={20} justify="space-between">
          <Logo width={100} />
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )
}
